import { Modal, Button, Form } from "react-bootstrap";

export default function UserNameDialogBox({
  open,
  close,
  name,
  setName,
  domain,
  setDomain,
  selectedCountry,
  setSelectedCountry,
  username,
  saveAs,
}) {
  const submitHandler = () => {
    if (!name.trim() && !domain.trim() && !selectedCountry.trim()) return;
    close();
    saveAs();
  };
  const countries = [
    "India",
    "Pakistan",
    "China",
    "Bhutan",
    "Bangladesh",
    "Nepal",
    "Myanmar",
  ];

  const labelStyle = {
    color: "black",
    margin: "5px 0px",
    fontSize: "15px",
    marginBottom: "3px",
    fontWeight: "500",
  };
  const inputStyle = {
    fontSize: "13px",
    padding: "6px 8px",
  };
  return (
    <Modal show={open} onHide={() => {}}>
      <Modal.Header style={{ margin: "0", padding: "0" }}>
        <Modal.Title
          style={{ color: "black", fontSize: "20px", padding: "10px 20px" }}
        >
          Supporting Leads For{" "}
          <strong className="text-blue-500">{username}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "10px 15px" }}>
        <Form>
          <Form.Group className="" controlId="formName">
            <Form.Label style={labelStyle}>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name"
              style={inputStyle}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mt-2" controlId="formDomain">
            <Form.Label style={labelStyle}>Domain</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter domain"
              style={inputStyle}
              value={domain}
              onChange={(e) => setDomain(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mt-2" controlId="formDomain">
            <Form.Label style={labelStyle}>Country</Form.Label>
            <Form.Control
              as="select"
              value={selectedCountry}
              style={inputStyle}
              className="mb-2"
              onChange={(e) => setSelectedCountry(e.target.value)}
            >
              <option value="">Select Country</option>
              {countries.map((country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "5px 15px",
        }}
      >
        <div>
          {!name && !domain && !selectedCountry && (
            <p
              className="text-red-600 m-0 p-0 flex items-center text-sm"
              style={{ color: "red" }}
            >
              Please fill either of the fields
            </p>
          )}
        </div>
        <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
          <Button variant="secondary" onClick={close}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={submitHandler}
            disabled={!name && !domain && !selectedCountry.trim()}
          >
            Save
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
