export const URL = "https://vagentapi.vpatrol.co.in";
export function validateTelecodeFormat(inputValue, countryInfo) {
  if (!inputValue || !countryInfo) return false;
  const substringToRight = countryInfo?.format?.split(" ").slice(1).join("");
  const firstPart = countryInfo.dialCode.length;
  const expectedDotCount = substringToRight.split("").reduce((count, char) => {
    if (char === ".") {
      count += 1;
    }
    return count;
  }, 0);
  return expectedDotCount + firstPart === inputValue.length;
}
export function convertTimestampToIST(timestamp) {
  const inputDate = new Date(timestamp); // Parse the input timestamp
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "Asia/Kolkata", // Indian Standard Time (IST)
  };

  const formattedDate = new Intl.DateTimeFormat("en-IN", options).format(
    inputDate
  );
  return formattedDate;
}

export function convertTimestamp(isoDateString) {
  const date = new Date(isoDateString);

  // Format the date and time
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true, // Use 12-hour format
    timeZone: "UTC", // Use UTC time zone for the ISO string
  };

  const formattedDate = date.toLocaleString("en-US", options);
  console.log(formattedDate);
}

export function convertDataSet(itemsArray) {
  const convertedDataset = {
    data: {
      email: itemsArray
        .filter((item) => item.type === "email")
        .map((item) => item.value),
      phone: itemsArray
        .filter((item) => item.type === "phone")
        .map((item) => item.value),
      cnic: itemsArray
        .filter((item) => item.type === "cnic")
        .map((item) => item.value),
      username: itemsArray
        .filter((item) => item.type === "username")
        .map((item) => ({
          [item.value]: {
            name: item.name || "",
            domain: item.domain || "",
            selectedCountry: item.selectedCountry || "",
          },
        })),
    },
    app: "vagent",
    type: "Individual",
  };
  console.log(convertedDataset);
  return convertedDataset;
}
export const PRDTOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiNjU2NTg3OTljZGJhNDc4ZTMwNDUwMTYwIiwiaWF0IjoxNzA2MzA4MTE3fQ.6lNM4FHQWSLGxH1pqb03wn0g9rf0RyYB5nF3Qgzv6Vk";

export const options = [
  { value: "phone", label: "Phone" },
  { value: "email", label: "Email" },
  { value: "username", label: "Username" },
  { value: "cnic", label: "CNIC" },
];
export const isValidEmail = (email) => {
  const emailRegex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})$/;
  return emailRegex.test(email);
};
function removeDuplicates(array) {
  if (array.length === 0) return [];
  const isPrimitiveArray = array.every(
    (item) => typeof item !== "object" || item === null
  );

  if (isPrimitiveArray) {
    return [...new Set(array)];
  } else {
    const uniqueObjects = new Map();

    array.forEach((item) => {
      const key = JSON.stringify(item);
      if (!uniqueObjects.has(key)) {
        uniqueObjects.set(key, item);
      }
    });

    return Array.from(uniqueObjects.values());
  }
}

const deduplicateLeakedCredentials = (leakedCredentials) => {
  const unique = [];
  const map = new Map();

  for (const item of leakedCredentials) {
    const uniqueKey = `${item.email}-${item.databaseName}-${item.password}`;

    if (!map.has(uniqueKey)) {
      map.set(uniqueKey, true);
      unique.push(item);
    }
  }

  return unique;
};

export function processResponseData(responseData) {
  const globalData = {
    nameVariants: [],
    emails: [],
    addresses: [],
    usernames: [],
    numbers: [],
    images: [],
    descriptions: [],
    age: null,
    religion: null,
    qualifications: [],
    school: null,
    profession: null,
    companyName: null,
    martialStatus: [],
    cnics: [],
    socialHandles: [],
    leakedCredentials: [],
  };
  try {
    responseData.response.forEach((responseItem) => {
      // dehashed done
      responseItem?.result?.dehashed?.results?.forEach((resultArray) => {
        resultArray?.forEach((item) => {
          const name = item?.name;
          const email = item?.email.trim();
          const username = item?.username;
          const address = item?.address;
          const phone = item?.phone;
          const databaseName = item?.database_name.trim();
          const password =
            item?.password.trim() || item?.hashed_password.trim();

          if (name && !globalData.nameVariants.includes(name)) {
            globalData.nameVariants.push(name);
          }
          if (email && !globalData.emails.includes(email)) {
            globalData.emails.push(email);
          }
          if (username && !globalData.usernames.includes(username)) {
            globalData.usernames.push(username);
          }
          if (address && !globalData.addresses.includes(address)) {
            globalData.addresses.push(address);
          }
          if (phone && !globalData.numbers.includes(phone)) {
            globalData.numbers.push(phone);
          }
          if (databaseName || email || password) {
            const leakedCredential = {
              databaseName: databaseName || "Not Available",
              email: email || "Not Available",
              password: password || "Not Available",
            };
            globalData.leakedCredentials.push(leakedCredential);
          }
        });
      });
      // truecaller done
      responseItem?.result?.truecaller?.forEach((resultArray) => {
        resultArray?.data?.forEach((entry) => {
          if (entry.name) {
            globalData.nameVariants.push(entry.name);
          }
          if (entry.image) {
            globalData.images.push(entry.image);
          }
          if (entry.about) {
            globalData.descriptions.push(entry.about);
          }
          entry.phones?.forEach((phone) => {
            if (phone.e164Format) {
              globalData.numbers.push(phone.e164Format);
            }
          });
          entry.addresses?.forEach((address) => {
            const fullAddress = [
              address.area,
              address.city,
              address.countryCode,
              address.timeZone,
            ]
              .filter(Boolean)
              .join(", ");
            if (fullAddress) {
              globalData.addresses.push(fullAddress);
            }
          });
          entry.internetAddresses?.forEach((internetAddress) => {
            if (internetAddress.id && internetAddress.service) {
              globalData.socialHandles.push({
                id: internetAddress.id,
                service: internetAddress.service,
                type: internetAddress.type,
              });
            }
          });
        });
      });
      // getsim details
      responseItem?.result?.getSimDetails?.forEach((result) => {
        result.data?.numbers?.forEach((simDetail) => {
          // Directly push data without checking for duplicates
          if (simDetail.address) {
            globalData.addresses.push(simDetail.address);
          }
          if (simDetail.cnic) {
            globalData.cnics.push(simDetail.cnic);
          }
          if (simDetail.number) {
            globalData.numbers.push(simDetail.number);
          }
          if (simDetail.name) {
            globalData.nameVariants.push(simDetail.name);
          }
        });
      });
      //  eyecons data
      responseItem?.result?.eyecons?.forEach((eyecon) => {
        if (eyecon.status === "success") {
          if (
            eyecon?.data?.fullName &&
            !globalData.nameVariants.includes(eyecon.data.fullName)
          ) {
            globalData.nameVariants.push(eyecon.data.fullName);
          }
          if (
            eyecon?.data?.image &&
            !globalData.images.includes(eyecon.data.image)
          ) {
            globalData.images.push(eyecon.data.image);
          }
          eyecon?.data?.otherNames?.forEach((nameObj) => {
            if (
              nameObj.name &&
              !globalData.nameVariants.includes(nameObj.name)
            ) {
              globalData.nameVariants.push(nameObj.name);
            }
          });
        }
      });
    });
    Object.keys(globalData).forEach((key) => {
      if (Array.isArray(globalData[key])) {
        globalData[key] = [...new Set(globalData[key])];
      }
    });
    globalData.leakedCredentials = removeDuplicates(
      globalData.leakedCredentials
    );
    globalData.socialHandles = removeDuplicates(globalData.socialHandles);
    return globalData;
  } catch (error) {
    console.log("The error occured in the data processing", error);
    return {
      nameVariants: [],
      emails: [],
      addresses: [],
      usernames: [],
      numbers: [],
      images: [],
      descriptions: [],
      age: null,
      religion: null,
      qualifications: [],
      school: null,
      profession: null,
      companyName: null,
      martialStatus: [],
      cnics: [],
      socialHandles: [],
      leakedCredentials: [],
    };
  }
}
export const mainTreeData = {
  data: {
    cnic: "3740574478825",
    foundKey: "cnic",
  },
  children: [
    {
      data: {
        number: "3354654443",
        name: "KHAWAJA MUHAMMAD SHAHBAZ WANI",
        cnic: "3740574478825",
        address: "House No ZB-6471 BANGASH COLON",
        operator: "Ufone",
        sourceIndicator: "source4",
        foundKey: "cnic",
      },
      children: [
        {
          data: {
            name: "KHAWAJA MUHAMMAD SHAHBAZ WANI",
            cnic: "3740574478825",
            address: "House No ZB-6471 BANGASH COLON",
            operator: "Ufone",
            parentNumber: "3354654443",
            childNumber: "3354654443",
            sourceIndicator: "source3",
            foundKey: "childNumber",
          },
          children: [],
        },
        {
          data: {
            name: "KHAWAJA MUHAMMAD SHAHBAZ WANI",
            cnic: "3740574478825",
            address: "House No ZB-6471 BANGASH COLON",
            operator: "Ufone",
            parentNumber: "3354654443",
            childNumber: "3350985098",
            sourceIndicator: "source3",
            foundKey: "childNumber",
          },
          children: [],
        },
        {
          data: {
            name: "kh muhammad shahbaz",
            cnic: "3740574478825",
            operator: "Zong",
            parentNumber: "3354654443",
            childNumber: "3110511377",
            sourceIndicator: "source3",
            foundKey: "childNumber",
          },
          children: [],
        },
      ],
    },
    {
      data: {
        number: "3350985098",
        name: "KHAWAJA MUHAMMAD SHAHBAZ WANI",
        cnic: "3740574478825",
        address: "House No ZB-6471 BANGASH COLON",
        operator: "Ufone",
        sourceIndicator: "source4",
        foundKey: "cnic",
      },
      children: [
        {
          data: {
            sourceIndicator: "source1",
            email: "jjamilahsaid@yahoo.com",
            password: "533880",
            phone: "3350985098",
            database_name: "AntiPublic",
            foundKey: "phone",
          },
          children: [],
        },
      ],
    },
    {
      data: {
        number: "3110511377",
        name: "kh muhammad shahbaz",
        cnic: "3740574478825",
        operator: "Zong",
        sourceIndicator: "source4",
        foundKey: "cnic",
      },
      children: [
        {
          data: {
            sourceIndicator: "source1",
            email: "jjamilahsaid@yahoo.com",
            password: "533880",
            phone: "3110511377",
            database_name: "AntiPublic",
            foundKey: "phone",
          },
          children: [],
        },
        {
          data: {
            addresses: [
              {
                countryCode: "US",
                type: "address",
              },
            ],
            email: "sajusajad9055200438@gmail.com",
            phone: {
              e164Format: "3110511377",
              numberType: "UNKNOWN",
              nationalFormat: "014778163",
              dialingCode: 1,
              countryCode: "US",
              carrier: "",
              type: "openPhone",
            },
            sourceIndicator: "source2",
            foundKey: "phone",
          },
          children: [],
        },
      ],
    },
  ],
};
const neededKey = {
  source1: [
    "email",
    "ip_address",
    "username",
    "password",
    "hashed_password",
    "name",
    "vin",
    "address",
    "phone",
    "database_name",
  ],
  source2: ["name", "addresses"],
  source3: ["name", "cnic", "address", "operator"],
  source4: ["number", "name", "cnic", "address", "operator"],
};
export function cleanData(responseData) {
  const processedData = {
    iteration1: {
      dehashed: [],
      trueCaller: [],
      eyeconsData: [],
      getSimDetails: [],
      getCnicDetails: [],
    },
    iteration2: {
      dehashed: [],
      trueCaller: [],
      eyeconsData: [],
      getSimDetails: [],
      getCnicDetails: [],
    },
    iteration3: {
      dehashed: [],
      trueCaller: [],
      eyeconsData: [],
      getSimDetails: [],
      getCnicDetails: [],
    },
  };
  responseData.response?.forEach((item) => {
    const iterationKey = `iteration${item.iteration + 1}`;
    if (processedData.hasOwnProperty(iterationKey)) {
      // data from dehashed
      item.result.dehashed.results?.forEach((resultArray) => {
        if (resultArray) {
          resultArray?.forEach((resultObject) => {
            if (resultObject) {
              let cleanedResultObject = { sourceIndicator: "source1" };
              neededKey.source1.forEach((key) => {
                if (resultObject[key]?.trim()) {
                  cleanedResultObject[key] = resultObject[key];
                }
              });
              processedData[iterationKey].dehashed.push(cleanedResultObject);
            }
          });
        }
      });
      // data from truecaller
      item.result.truecaller.forEach((callerEntry) => {
        if (callerEntry.status === "success" && callerEntry.data) {
          callerEntry.data.forEach((dataEntry) => {
            // Create arrays to hold emails and phones for this entry
            const emails = dataEntry.internetAddresses
              .filter((address) => address.service === "email")
              .map((address) => address.id);
            const phones = dataEntry.phones.map((phone) => phone);

            const filteredData = neededKey.source2.reduce((acc, key) => {
              if (dataEntry[key]) {
                acc[key] = dataEntry[key];
              }
              return acc;
            }, {});

            // Generate all combinations of emails and phones
            emails.forEach((email) => {
              phones.forEach((phone) => {
                const newNode = {
                  ...filteredData,
                  email,
                  phone,
                  sourceIndicator: "source2",
                };
                processedData[iterationKey].trueCaller.push(newNode);
              });
            });

            // In case there are emails but no phones, create nodes for each email alone
            if (phones.length === 0) {
              emails.forEach((email) => {
                const newNode = {
                  ...filteredData,
                  email,
                  sourceIndicator: "source2",
                };
                processedData[iterationKey].trueCaller.push(newNode);
              });
            }

            // Similarly, in case there are phones but no emails, create nodes for each phone alone
            if (emails.length === 0) {
              phones.forEach((phone) => {
                const newNode = {
                  ...filteredData,
                  phone,
                  sourceIndicator: "source2",
                };
                processedData[iterationKey].trueCaller.push(newNode);
              });
            }
          });
        }
      });
      // data from getSimDetails
      item.result.getSimDetails.forEach((simDetailEntry) => {
        if (simDetailEntry.data && simDetailEntry.data.numbers) {
          const parentNumber = simDetailEntry.data.number;
          simDetailEntry.data.numbers.forEach((numberEntry) => {
            const filteredData = neededKey.source3.reduce((acc, key) => {
              if (numberEntry[key]?.trim()) {
                acc[key] = numberEntry[key];
              }
              return acc;
            }, {});

            const newNode = {
              ...filteredData,
              parentNumber: parentNumber,
              childNumber: numberEntry.number,
              sourceIndicator: "source3",
            };
            processedData[iterationKey].getSimDetails.push(newNode);
          });
        }
      });
      // data from getCnicDetails
      item.result.getCnicDetails.forEach((cnicDetailEntry) => {
        if (cnicDetailEntry.data && cnicDetailEntry.data.numbers) {
          cnicDetailEntry.data.numbers.forEach((cnicEntry) => {
            const filteredData = neededKey.source4.reduce((acc, key) => {
              if (cnicEntry[key]?.trim()) {
                acc[key] = cnicEntry[key];
              }
              return acc;
            }, {});
            processedData[iterationKey].getCnicDetails.push({
              ...filteredData,
              sourceIndicator: "source4",
            });
          });
        }
      });
    }
  });
  return processedData;
}
export function buildTree(mainKeys, responseData) {
  let tree = [];

  function searchAndBuild(keyType, keyValue, parentNode, iteration) {
    if (iteration > 3) {
      return;
    }

    const iterationData = responseData[`iteration${iteration}`];
    if (!iterationData) {
      return;
    }

    //  for dehashed data only
    if (keyType === "phone" || keyType === "username" || keyType === "email") {
      const dehashedData = iterationData.dehashed || [];
      dehashedData.forEach((data) => {
        let isMatch = false;
        if (keyType === "username") {
          isMatch =
            data[keyType]?.trim().toLowerCase() ===
            keyValue.trim().toLowerCase();
        } else if (keyValue && keyType === "email") {
          isMatch = data[keyType]?.trim() === keyValue.trim();
        } else if (keyType === "phone") {
          const trimmedDataPhone = data[keyType]?.trim();
          const trimmedKeyPhone = keyValue?.trim();
          if (trimmedDataPhone && trimmedKeyPhone) {
            isMatch =
              trimmedDataPhone.includes(trimmedKeyPhone) ||
              trimmedKeyPhone.includes(trimmedDataPhone);
          }
        }

        if (isMatch) {
          const childNode = {
            data: { ...data, foundKey: keyType },
            children: [],
          };
          parentNode.children.push(childNode);

          let nextSearchKeys = [];
          if (keyType === "username") {
            nextSearchKeys = ["email", "phone"];
          } else if (keyType === "phone") {
            nextSearchKeys = ["email"];
          } else if (keyType === "email") {
            nextSearchKeys = ["phone"];
          }

          nextSearchKeys.forEach((attr) => {
            if (data[attr] && attr !== keyType) {
              searchAndBuild(attr, data[attr], childNode, iteration + 1);
            }
          });
        }
      });
    }

    // for trueCaller data only
    if (keyType === "phone" || keyType === "email") {
      const trueCallerData = iterationData.trueCaller || [];
      trueCallerData.forEach((data) => {
        let isMatch = false;
        if (keyValue && keyType === "email") {
          isMatch = data[keyType]?.trim() === keyValue.trim();
        } else if (keyType === "phone") {
          const trimmedDataPhone = data[keyType]?.e164Format?.trim();
          const trimmedKeyPhone = keyValue?.trim();
          if (trimmedDataPhone && trimmedKeyPhone) {
            isMatch =
              trimmedDataPhone.includes(trimmedKeyPhone) ||
              trimmedKeyPhone.includes(trimmedDataPhone);
          }
        }

        if (isMatch) {
          const childNode = {
            data: { ...data, foundKey: keyType },
            children: [],
          };
          parentNode.children.push(childNode);

          let nextSearchKeys = [];
          if (keyType === "phone") {
            nextSearchKeys = ["email"];
          } else if (keyType === "email") {
            nextSearchKeys = ["phone"];
          }

          nextSearchKeys.forEach((attr) => {
            if (data[attr] && attr !== keyType) {
              searchAndBuild(
                attr,
                data[attr].e164Format,
                childNode,
                iteration + 1
              );
            }
          });
        }
      });
    }

    // for getSimDetails data only
    if (keyType === "phone") {
      const getSimDetailsData = iterationData.getSimDetails || [];
      getSimDetailsData.forEach((data) => {
        let isMatch = false;
        const trimmedDataPhone = data?.parentNumber?.trim();
        const trimmedKeyPhone = keyValue?.trim();
        if (trimmedDataPhone && trimmedKeyPhone) {
          isMatch =
            trimmedDataPhone.includes(trimmedKeyPhone) ||
            trimmedKeyPhone.includes(trimmedDataPhone);
        }

        if (isMatch) {
          const childNode = {
            data: { ...data, foundKey: "childNumber" },
            children: [],
          };
          parentNode.children.push(childNode);

          if (data.parentNumber?.trim() !== data.childNumber?.trim()) {
            searchAndBuild("phone", data.childNumber, childNode, iteration + 1);
          }
        }
      });
    }

    // for getCnicDetails data only
    if (keyType === "cnic") {
      const getCnicDetailsData = iterationData.getCnicDetails || [];
      getCnicDetailsData.forEach((data) => {
        let isMatch = false;
        isMatch = data[keyType]?.trim() === keyValue.trim();

        if (isMatch) {
          const childNode = {
            data: { ...data, foundKey: keyType },
            children: [],
          };
          parentNode.children.push(childNode);

          if (data.number?.trim()) {
            searchAndBuild(
              "phone",
              data.number?.trim(),
              childNode,
              iteration + 1
            );
          }
        }
      });
    }
  }

  Object.keys(mainKeys).forEach((keyType) => {
    if (["email", "phone", "username", "cnic"].includes(keyType)) {
      mainKeys[keyType].forEach((keyValue) => {
        const rootNode = {
          data: {
            [keyType]: keyValue,
            foundKey: keyType,
            sourceIndicator: "root",
          },
          children: [],
        };
        tree.push(rootNode);
        searchAndBuild(keyType, keyValue, rootNode, 1);
      });
    }
  });

  return tree;
}
export function formatKeyValueWithEllipsis(key, value, maxLength) {
  // Format the key based on the specified conditions
  const formattedKey =
    key.slice(-6) === "Number"
      ? "Phone"
      : key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();

  // Combine the formatted key with the value
  let combinedString = `${formattedKey}:${value}`;

  // Apply ellipsis if the combined string exceeds maxLength
  if (combinedString.length > maxLength) {
    combinedString = combinedString.substring(0, maxLength - 3) + "...";
  }

  return combinedString;
}

const keyMappings = [
  { name: "Name" },
  { phone: "Phone" },
  { email: "Email" },
  { password: "Password" },
  { vin: "Vehicle Number" },
  { hashed_password: "Encrypted Password" },
  { address: "Address" },
  { database_name: "Leaked Database Name" },
  { foundKey: "Identifier Key" },
  { username: "Username" },
  { e164Format: "Phone Number" },
  { numberType: "Phone Type" },
  { nationalFormat: "National Phone Number" },
  { dialingCode: "Dial Code" },
  { countryCode: "Country Code" },
  { carrier: "Carrier" },
  { type: "Record Type" },
  { cnic: "Cnic" },
  { operator: "Operator" },
  { childNumber: "Phone" },
];
const keyMappingsLookup = keyMappings.reduce((lookup, obj) => {
  const key = Object.keys(obj)[0];
  lookup[key] = obj[key];
  return lookup;
}, {});

// Function to get the friendly name for a key
export function getFriendlyKeyName(key) {
  return keyMappingsLookup[key] || key;
}
export function keyValueMapper(value) {
  try {
    if (typeof value !== "string" || value === null || !value.trim()) {
      return "N.A.";
    }
    let val = value.trim();
    switch (val) {
      case "childNumber":
        return "Phone";
      default:
        return val.charAt(0).toUpperCase() + val.slice(1);
    }
  } catch (error) {
    console.error("Error in keyValueMapper:", error);
    return "N.A.";
  }
}
