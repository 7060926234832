import React, { useEffect, useRef, useState } from "react";
import {
  formatKeyValueWithEllipsis,
  getFriendlyKeyName,
  keyValueMapper,
} from "./GlobalFunctions";
import * as d3 from "d3";

function TreeVisualization({ data }) {
  const d3Container = useRef(null);
  useEffect(() => {
    if (data && d3Container.current) {
      drawTree(d3Container.current, data);
    }
  }, [data]);
  function drawTree(container, data) {
    const margin = { top: 10, right: 40, bottom: 10, left: 40 };
    const width = 550 - margin.left - margin.right;
    const height = 450 - margin.top - margin.bottom;
    d3.select(d3Container.current).selectAll("*").remove();

    const svg = d3
      .select(container)
      .attr("viewBox", [
        0,
        0,
        width + margin.left + margin.right,
        height + margin.top + margin.bottom,
      ]);

    const root = d3.hierarchy(data);

    const treeLayout = d3.tree().size([height, width]);
    treeLayout(root);

    let g = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Draw links
    g.selectAll(".link")
      .data(root.links())
      .enter()
      .append("path")
      .attr("class", "link")
      .attr(
        "d",
        d3
          .linkHorizontal()
          .x((d) => d.y)
          .y((d) => d.x)
      )
      .attr("fill", "none")
      .attr("stroke", "#ccc")
      .attr("stroke-opacity", 0.7);

    const node = g
      .selectAll(".node")
      .data(root.descendants())
      .enter()
      .append("g")
      .attr("class", "node")
      .attr("transform", (d) => `translate(${d.y},${d.x})`);
    node
      .append("circle")
      .attr("r", 5)
      .attr("class", (d) => (d.depth === 0 ? "blinkRoot" : null))
      .attr("fill", (d) => (d.depth === 0 ? "red" : "#999"));
    node
      .filter((d) => !d.children)
      .select("circle")
      .classed("blinkLeaf", true)
      .attr("fill", "rgb(0, 152, 0)");

    node.each(function (d) {
      const node = d3.select(this);
      const text = node
        .append("text")
        .attr("dy", "0.35em")
        .attr("x", (d) => (d.children ? -7 : 7))
        .attr("text-anchor", (d) => (d.children ? "end" : "start"))
        .text((d) => {
          let key = d.data.data.foundKey;
          let value = "N.A.";
          if (
            d.data.data.sourceIndicator === "source2" &&
            d.data.data.foundKey === "phone"
          ) {
            value = d.data.data.phone.e164Format;
          } else {
            value = d.data.data[key];
          }
          return formatKeyValueWithEllipsis(key, value, 20);
        })
        .style("fill", "white")
        .style("font-size", "13px")
        .style("font-family", "Arial, sans-serif")
        .style("letter-spacing", "0.5px");

      const bbox = text.node().getBBox();

      const padding = 2;

      node
        .insert("rect", "text")
        .attr("x", bbox.x - padding)
        .attr("y", bbox.y - padding)
        .attr("width", bbox.width + padding * 2)
        .attr("height", bbox.height + padding * 2)
        .attr("fill", "#202020");

      text
        .clone(true)
        .lower()
        .attr("stroke", "black")
        .attr("stroke-width", "0.5px");

      node.append("title").text((d) => {
        let content = d?.data?.data;
        if (!content) return "No data found";

        let tooltipText = [];
        if (content.sourceIndicator === "root" && content[content.foundKey]) {
          tooltipText.push(
            `${getFriendlyKeyName(content.foundKey)}: ${
              content[content.foundKey]
            }`
          );
        } else if (
          content.sourceIndicator === "source1" ||
          content.sourceIndicator === "source3" ||
          content.sourceIndicator === "source4"
        ) {
          const priorityKeys = [
            "name",
            "email",
            "phone",
            "username",
            "password",
            "hashed_password",
            "address",
            "database_name",
            "vin",
            "e164Format",
            "numberType",
            "dialingCode",
            "countryCode",
            "carrier",
            "cnic",
            "operator",
            "childNumber",
            "foundKey",
          ];
          priorityKeys.forEach((key) => {
            if (content[key]) {
              tooltipText.push(
                `${getFriendlyKeyName(key)}:  ${
                  key === "foundKey"
                    ? keyValueMapper(content[key])
                    : content[key]
                }`
              );
            }
          });
        } else if (content.sourceIndicator === "source2") {
          const priorityKeys = [
            "name",
            "email",
            "phone",
            "addresses",
            "foundKey",
          ];
          priorityKeys.forEach((key) => {
            if (content[key]) {
              if (key === "phone") {
                Object.entries(content[key]).forEach(([subKey, value]) => {
                  if (
                    value &&
                    subKey !== "nationalFormat" &&
                    subKey !== "type"
                  ) {
                    tooltipText.push(
                      `${getFriendlyKeyName(subKey)}: ${
                        key === "foundKey" ? keyValueMapper(value) : value
                      }`
                    );
                  }
                });
              } else if (key === "addresses") {
                content[key].forEach((address, index) => {
                  let addressDetails = Object.entries(address)
                    .filter(([subKey, value]) => subKey !== "type" && value)
                    .map(([subKey, value]) => `${value}`)
                    .join(", ");
                  if (addressDetails) {
                    tooltipText.push(`Address${index + 1}: ${addressDetails}`);
                  }
                });
              } else {
                tooltipText.push(
                  `${getFriendlyKeyName(key)}: ${
                    key === "foundKey"
                      ? keyValueMapper(content[key])
                      : content[key]
                  }`
                );
              }
            }
          });
        }
        return tooltipText.join("\n");
      });
    });
  }

  return (
    <>
      <svg ref={d3Container} style={{ width: "100%", height: "500px" }}></svg>
    </>
  );
}

export default TreeVisualization;
