import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
const AuthCheck = ({ children }) => {
  const cookies = useCookies()[0];
  const isDevelopment = process.env.REACT_APP_DEV === "development";

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        if (
          !cookies[process.env.REACT_APP_PROD_REF_COOKIE] ||
          !cookies[process.env.REACT_APP_PROD_COOKIE]
        ) {
          if (!isDevelopment) {
            toast.error("Unauthorised User!!");

            window.location.href = "https://products.vpatrol.co.in";
          }
        }
      } catch (error) {
        console.error(error);
        toast.error("Something went wrong!!");
        window.location.href = "https://products.vpatrol.co.in";
      }
    };
    checkAuthentication();
  }, [cookies, isDevelopment]);

  return <>{children}</>;
};

export default AuthCheck;
