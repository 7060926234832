import "./App.css";
import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import UserNameDialogBox from "./Components/UserNameDialogBox";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AuthCheck from "./Components/AuthCheck";
import axios from "axios";
import { useCookies } from "react-cookie";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  validateTelecodeFormat,
  convertDataSet,
  URL,
  PRDTOKEN,
  options,
  isValidEmail,
} from "./Components/GlobalFunctions";
import CustomSelect from "./Components/CustomSelect";
import toast from "react-hot-toast";
import { LoadingIcon } from "./assets/LoadingIcon";
import TableDashboard from "./Components/TableDashboard";
import {
  UilEnvelope,
  UilPhone,
  UilUserSquare,
  UilLockAccess,
  UilTrashAlt,
} from "@iconscout/react-unicons";

const DashBoard = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [name, setName] = useState("");
  const [domain, setDomain] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");

  const [showDialogBox, setShowDialogBox] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [teleCode, setTeleCode] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showScrollIndicator, setShowScrollIndicator] = useState(true);
  const [cookies] = useCookies([]);

  const [page, setPage] = useState({ page: 1, totalCount: 0 });
  const [user, setUser] = useState({ plan: "N.A.", countLeft: "N.A." });
  const [items, setItems] = useState([]);
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    document.title = "VAGENT";
    const handleScroll = () => {
      if (window.scrollY > 150) {
        setShowScrollIndicator(false);
      } else {
        setShowScrollIndicator(true);
      }
    };
    fetchData(1);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const fetchData = async (currentPage) => {
    try {
      const response = await axios.get(
        `${URL}/api/details/tasks?per_page=${5}&p=${currentPage}`,
        {
          headers: {
            prdtoken:
              process.env.REACT_APP_DEV === "development"
                ? PRDTOKEN
                : cookies[process.env.REACT_APP_PROD_COOKIE],
          },
        }
      );
      console.log(
        `fetch Data called in dashboard, page is ${currentPage} `,
        response.data
      );
      if (response?.data?.data) {
        setTasks(response.data.data);
      }
      if (response?.data?.totalCount) {
        setPage((prevData) => ({
          ...prevData,
          totalCount: response.data.totalCount,
        }));
      }
      if (response.data?.user) {
        setUser((prevData) => ({
          ...prevData,
          plan: response.data.user.plan,
          countLeft: response.data.user.searches,
        }));
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteItem = (id) => {
    const updatedItems = items.filter((item) => item.id !== id);
    setItems(updatedItems);
  };
  const onSelectChange = (value) => {
    setInputValue("");
    setSelectedOption(value);
  };
  const handleAddItems = () => {
    try {
      if (!inputValue.trim() || !selectedOption.trim()) return;

      if (
        selectedOption === "username" &&
        !domain.trim() &&
        !name.trim() &&
        !selectedCountry.trim()
      ) {
        setShowDialogBox(true);
        return;
      }
      if (selectedOption === "phone" && teleCode) {
        if (!validateTelecodeFormat(inputValue, teleCode)) {
          toast.error("Invalid phone number!");
          return;
        }
      }

      if (selectedOption === "email" && !isValidEmail(inputValue)) {
        toast.error("Invalid email address!");
        return;
      }

      const userData = {
        id: uuidv4(),
        type: selectedOption,
        value: selectedOption === "phone" ? "+" + inputValue : inputValue,
        ...(selectedOption === "username" && { name, domain, selectedCountry }),
      };
      console.log("userdata is", userData);
      setItems((prevItems) => {
        return [...prevItems, userData];
      });
      setInputValue("");
      setName("");
      setDomain("");
      setSelectedCountry("");
      setTeleCode(null);
    } catch (error) {
      toast.error("Please re-enter the field again");
      setInputValue("");
      setName("");
      setDomain("");
      setSelectedCountry("");
    }
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const submitHandler = async () => {
    try {
      setLoading(true);

      const dataToSend = convertDataSet(items);
      console.log("data to send is ", dataToSend);
      await axios.post(`${URL}/api/details`, dataToSend, {
        headers: {
          prdtoken:
            process.env.REACT_APP_DEV === "development"
              ? PRDTOKEN
              : cookies[process.env.REACT_APP_PROD_COOKIE],
        },
      });
      toast.success("Investigation added successfully");
      setPage((prevData) => ({
        ...prevData,
        page: 1,
      }));
      setItems([]);
      await fetchData(1);
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to create investigation.");
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  };

  const headerStyle = {
    backgroundColor: "#202020",
    color: "#ffffff",
    padding: "15px 35px",
    display: "flex",
    justifyContent: "space-between",
    letterSpacing: "1px",
    alignItems: "center",
    boxShadow: "0px 4px 10px rgba(255, 255, 255, 0.2)",
  };

  const labelStyle = {
    color: "#61dafb",
  };

  const valueStyle = {
    color: "#ffffff",
  };
  const smoothScrolling = () => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    window.scrollTo({
      top: documentHeight - windowHeight,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div
        style={{
          position: "sticky",
          top: "0",
          zIndex: "999",
          margin: "0",
        }}
      >
        <div className="manage-group-header" style={headerStyle}>
          <div className="group-header-left">
            <span>VAgent</span>
            <span>The Digital Profiler</span>
          </div>
          <div className="manage-console-header-right">
            <p>
              <span style={labelStyle}>Product name</span>
              <span style={valueStyle}>VAgent</span>
            </p>
            <p>
              <span style={labelStyle}>Product plan</span>
              <span style={valueStyle}>{user.plan}</span>
            </p>
            <p>
              <span style={labelStyle}>Count left</span>
              <span style={valueStyle}>{user.countLeft}</span>
            </p>
          </div>
        </div>
      </div>
      <div
        className="relative w-screen main_screen"
        style={{ position: "relative" }}
      >
        <div className="flex flex-col justify-center mt-3">
          <div
            className="border-white text-black m-auto letter-spacing p-3 flex flex-col"
            style={{}}
          >
            {" "}
            <div
              className="flex justify-center m-auto gap-0 bg-[#1B2E4B] rounded-md"
              style={{
                width: "743px",
                height: "48px",
                marginBottom: "40px",
                borderRadius: "7px",
                backgroundColor: "#1B2E4B",
              }}
            >
              <CustomSelect
                onChange={(value) => onSelectChange(value)}
                options={options}
              />

              <div className="flex items-center border-none border-gray-500 flex-1 p-0 m-0">
                {selectedOption !== "phone" ? (
                  <input
                    type="text"
                    name={selectedOption}
                    value={inputValue}
                    onChange={handleInputChange}
                    disabled={!selectedOption || loading}
                    placeholder={
                      selectedOption === ""
                        ? "Select an option to enable input"
                        : selectedOption !== "phone"
                        ? `Enter ${selectedOption}`
                        : "Include country code (e.g., +91)"
                    }
                    className={`w-full border-none py-2 px-3 leading-tight text-white focus:outline-none focus:border-blue-500 focus:shadow-outline-blue focus:ring focus:ring-blue-200 tracking-wider placeholder:text-sm bg-transparent
                placeholder:text-gray-400 caret-white font-bold placeholder:px-0 custom_select_style_input_tag`}
                    autoComplete="off"
                  />
                ) : (
                  <PhoneInput
                    country={"us"}
                    value={inputValue}
                    onChange={(value, country) => {
                      console.log(value, country);
                      setTeleCode(country);
                      setInputValue(value);
                    }}
                    loading={loading}
                    containerClass="phone_input_container_class"
                    inputClass="phone_input_input_class"
                    buttonClass="phone_input_button_class"
                    dropdownClass=""
                    searchClass="phone_input_search_class"
                  />
                )}
              </div>

              <button
                className={`flex py-0 justify-center items-center text-white w-32 border-none rounded-none bg-blue-500 rounded-tr-md rounded-br-md font-bold uppercase ${
                  loading ? "cursor-not-allowed bg-blue-400" : ""
                }`}
                disabled={loading}
                onClick={handleAddItems}
                style={{
                  borderTopRightRadius: "8px",
                  borderBottomRightRadius: "8px",
                }}
              >
                Add
              </button>
            </div>
            {items.length > 0 && (
              <div
                className="list-outer-container"
                style={{
                  position: "relative",
                  padding: "15px 0",
                  borderRadius: "6px",
                  outline: "1px solid white",
                  marginTop: "50px",
                }}
              >
                <p
                  className="text-center text-white m-0"
                  style={{
                    fontSize: "21px",
                    position: "absolute",
                    top: "-17px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    backgroundColor: "#202020",
                    letterSpacing: "1px",
                    padding: "0 5px",
                  }}
                >
                  Input Leads
                </p>
                {items.length > 0 ? (
                  <ul
                    className={`w-full m-0 p-0 rounded-lg max-h-64 overflow-y-auto flex justify-center custom-scrollbar ${
                      loading ? "opacity-40" : ""
                    }`}
                    style={{
                      width: "725px",
                      maxHeight: "256px",
                      scrollBehavior: "smooth",
                      padding: "0",
                      margin: "0",
                    }}
                  >
                    {items.map((item, index) => (
                      <li key={index}>
                        <p className="type">
                          {item.type === "cnic" ? (
                            <UilLockAccess color="rgb(59 130 246)" />
                          ) : item.type === "username" ? (
                            <UilUserSquare color="rgb(59 130 246)" />
                          ) : item.type === "phone" ? (
                            <UilPhone color="rgb(59 130 246)" />
                          ) : item.type === "email" ? (
                            <UilEnvelope color="rgb(59 130 246)" />
                          ) : (
                            ""
                          )}
                        </p>
                        <p className="type_name">
                          {item.type === "username" ? (
                            <>
                              {item.value} {item.name && ` - ${item.name}`}
                              {item.domain && ` - ${item.domain}`}{" "}
                              {item.selectedCountry &&
                                ` - ${item.selectedCountry}`}
                            </>
                          ) : (
                            item.value
                          )}
                        </p>
                        <button
                          onClick={() => handleDeleteItem(item.id)}
                          className={`${
                            loading ? "cursor-not-allowed" : ""
                          } delete-button`}
                          disabled={loading}
                        >
                          <UilTrashAlt />
                        </button>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <></>
                )}
              </div>
            )}
            {items.length > 0 && (
              <div
                style={{
                  marginTop: "35px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  type="button"
                  onClick={submitHandler}
                  disabled={loading}
                  style={{
                    height: "40px",
                    margin: "auto",
                    padding: "10px 20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "2px",
                    cursor: loading ? "not-allowed" : "pointer",
                    backgroundColor: loading && "rgb(147 197 253)",
                  }}
                >
                  {loading ? (
                    <>
                      Processing... <LoadingIcon size="1.1em" />
                    </>
                  ) : (
                    <>Send Request</>
                  )}
                </button>
              </div>
            )}
          </div>
          {showDialogBox && (
            <UserNameDialogBox
              name={name}
              setName={setName}
              domain={domain}
              setDomain={setDomain}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              close={() => setShowDialogBox(false)}
              open={true}
              selectedOption={selectedOption}
              username={inputValue}
              saveAs={handleAddItems}
            />
          )}
        </div>
        {showScrollIndicator && (
          <div
            style={{
              position: "absolute",
              bottom: "45px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <div className="scroll" onClick={smoothScrolling}>
              {" "}
              <div style={{ position: "relative" }}>
                <span
                  style={{
                    position: "absolute",
                    top: "-30px",
                    left: "-73px",
                    whiteSpace: "nowrap",
                    color: "rgba(255, 255, 255, 0.623)",
                  }}
                >
                  Scroll down to view tasks
                </span>
              </div>
            </div>
          </div>
        )}
      </div>

      <TableDashboard
        page={page}
        setPage={setPage}
        items={tasks}
        setItems={setTasks}
        fetchData={(size) => fetchData(size)}
        loading={loading}
        setLoading={setLoading}
      />
    </>
  );
};

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <AuthCheck>
              <DashBoard />
            </AuthCheck>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
