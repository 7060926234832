import React from "react";

export function RightArrow(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.5em"
      height="1.5em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill={!props.color ? "#007bff" : props.color}
        d="M10 17V7l5 5l-5 5Z"
      ></path>
    </svg>
  );
}
export default RightArrow;
