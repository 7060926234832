import React from "react";
import { cleanData, buildTree } from "./GlobalFunctions";
import TreeSample from "./TreeSample";

const CreateTree = ({ mainKeys = null, responseData = null }) => {
  if (!mainKeys || !responseData) return <div>No data found</div>;
  mainKeys = {
    ...mainKeys,
    username: mainKeys.username.map((userObj) => Object.keys(userObj)[0]),
  };
  const cleanedData = cleanData(responseData);
  let tree = buildTree(mainKeys, cleanedData);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      {" "}
      {tree.map((mainTreeData, index) => (
        <div key={index}>
          <TreeSample data={mainTreeData} />
        </div>
      ))}
    </div>
  );
};

export default CreateTree;
