import { useState, useRef, useEffect } from "react";
import Pagination from "./Pagination";
import TableHeading from "./TableHeading";
import { Modal, Button, Spinner } from "react-bootstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";
import {
  URL,
  PRDTOKEN,
  convertTimestampToIST,
  processResponseData,
} from "./GlobalFunctions";
import toast from "react-hot-toast";
import axios from "axios";
import {
  UilInfoCircle,
  UilEye,
  UilEllipsisV,
  UilTrashAlt,
  UilImport,
} from "@iconscout/react-unicons";
import { useCookies } from "react-cookie";
import CreateTree from "./CreateTree";
// import { saveAs } from "file-saver";
// import JSZip from "jszip";

const TableDashboard = ({
  page,
  setPage,
  items,
  setItems,
  fetchData,
  loading,
}) => {
  const [sortOrder, setSortOrder] = useState("asc");
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [itemToBeDeleted, setItemToBeDeleted] = useState(null);
  const [tootTipContent, setToolTipContent] = useState(null);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [cookies] = useCookies([]);

  const [showResponseModal, setShowResponseModal] = useState(false);
  const [modelLoading, setModelLoading] = useState(false);
  const [textData, setTextData] = useState({
    mainKeys: null,
    responseData: null,
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdownId(null);
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef, setOpenDropdownId]);
  const handleHeaderClick = (event) => {
    const thElement = event.target.closest("th[data-column]");

    if (thElement) {
      const columnName = thElement.getAttribute("data-column");
      console.log(columnName);

      if (columnName) {
        const sortedItems = items.slice().sort((a, b) => {
          if (columnName === "Date") {
            const columnA = new Date(a.createdAt).getTime();
            const columnB = new Date(b.createdAt).getTime();

            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          } else if (columnName === "Leads") {
            const columnA = a.leads;
            if (columnA === "Single") {
              return sortOrder === "asc" ? -1 : 1;
            } else if (columnA === "Multiple") {
              return sortOrder === "asc" ? 1 : -1;
            }
          } else if (columnName === "Status") {
            const columnA = a.status;
            if (columnA) {
              return sortOrder === "asc" ? -1 : 1;
            } else if (!columnA) {
              return sortOrder === "asc" ? 1 : -1;
            }
          }
        });
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        setItems(sortedItems);
      }
    }
  };
  const handleMouseOver = (itemId) => {
    const itemInfo = items.find((item) => item._id === itemId);
    setToolTipContent(itemInfo);
  };
  const handleViewClick = async (item) => {
    if (!item?._id || !item?.input) return;
    try {
      setModelLoading(true);
      setShowResponseModal(true);
      setTextData({ mainKeys: item.input });
      const response = await axios.get(
        `${URL}/api/details/response/${item._id}`,
        {
          headers: {
            mode: "no-cors",
            prdtoken:
              process.env.REACT_APP_DEV === "development"
                ? PRDTOKEN
                : cookies[process.env.REACT_APP_PROD_COOKIE],
          },
        }
      );
      // console.log("Response from the Response API", response.data);
      const textData = await response.data.data;
      setTextData((prevState) => ({
        ...prevState,
        responseData: textData,
      }));
    } catch (error) {
      console.error("Error:", error);
      setTextData({ mainKeys: null, responseData: null });
    } finally {
      setModelLoading(false);
    }
  };
  const handleBarsButtonClick = (id, event) => {
    event.stopPropagation();
    if (id !== openDropdownId) {
      setOpenDropdownId(id);
      return;
    }
    if (openDropdownId) {
      setOpenDropdownId(null);
      return;
    }
    setOpenDropdownId(id);
  };
  const handleDeleteButtonClick = async (id) => {
    setOpenDropdownId(null);
    setItemToBeDeleted(id);
    setShowDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const handleCloseResponseModal = () => {
    setShowResponseModal(false);
    setTextData({ mainKeys: null, responseData: null });
  };
  const handleConfirmDelete = async () => {
    if (!itemToBeDeleted) {
      setShowDeleteModal(false);
      return;
    }
    try {
      setDeleteLoader(true);
      const response = await axios.delete(
        `${URL}/api/details/delete/${itemToBeDeleted}`,
        {
          headers: {
            prdtoken:
              process.env.REACT_APP_DEV === "development"
                ? PRDTOKEN
                : cookies[process.env.REACT_APP_PROD_COOKIE],
          },
        }
      );
      console.log("deleted task successfully:- ", response.data);
      if (page.totalCount <= 5) {
        console.log("When page count is less than 5");
        await fetchData(1);
      } else {
        if (items.length > 1) {
          console.log("When items count is more than one");
          await fetchData(page.page);
        } else {
          if (items.length === 1) {
            console.log(`When items count is equal to one ${page.page}`);
            await fetchData(page.page - 1);
            setPage((prevPage) => ({
              ...prevPage,
              page: prevPage.page - 1,
            }));
          }
        }
      }
      toast.success("Task deleted successfully");
      setShowDeleteModal(false);
    } catch (error) {
      console.error("Error:", error);
      toast.error("Unable to delete the task");
    } finally {
      setDeleteLoader(false);
    }
  };
  const downloadHTML = async () => {
    if (!openDropdownId) {
      toast.error("Invalid request!");
      return;
    }
    try {
      setModelLoading(true);
      const response = await axios.get(
        `${URL}/api/details/response/${openDropdownId}`,
        {
          headers: {
            mode: "no-cors",
            prdtoken:
              process.env.REACT_APP_DEV === "development"
                ? PRDTOKEN
                : cookies[process.env.REACT_APP_PROD_COOKIE],
          },
        }
      );
      const textData = await response.data.data;
      const data = processResponseData(textData);
      console.log(data);
      const htmlContent = `
  <!DOCTYPE html>
  <html lang="en">
  <head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <link rel="stylesheet" href="https://unicons.iconscout.com/release/v3.0.6/css/line.css">
  <title>Profile Report</title>
  <style>
  body {
    font-family: Arial, sans-serif;
    width: 850px;
    margin: 15px auto;
    height: auto;
    padding: 20px;
    border:1px solid green;
    
  }
    table {
      width: 100%;
      border-collapse: collapse;
      outline: 2px solid #ccc;
      overflow: hidden;
      border-radius: 4px;
    }
    table, th, td {
    }
    .title-col {
      width: 180px;
      font-weight: bold;
    }
    th, td {
      padding: 10px;
      text-align: left;
    }
    th {
      background-color: #4CAF50;
      color: white;
    }
    tr:nth-child(even) {
      background-color: #FFFFFF;
  }
  tr:nth-child(odd) {
      background-color: #ededed; 
  }
    .single-item-list {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
    .multi-item-list {
      list-style-position: inside;
      padding: 0;
      margin:0;
    }
    h2 {
      text-align: center;
      padding: 0;
      margin: 10px;
      margin-bottom: 20px;
      color: #4caf50;
    }
    .un-list{
      display:flex;
      flex-direction:column;
    }
    .overflow-ellipsis {
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 30px;
      max-width:200px
    }
    .leaked-credentials-data{
      text-align:left;
      color:black;
      margin-top:20px;
      margin-bottom:10px;
      font-size: 18px;
      font-weight:bold;
    }
    .no-data-found {
      text-align: center;
      color: #999; 
      font-style: italic;
      padding: 10px 0;
    }
    .copy-icon {
      position: absolute;
      right: 10px; 
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
    .index_width {
      width: 30px; 
    }
    
    .email_width {
      width: 150px; 
      position: relative; 
      padding-right: 30px;
    }
    
    .databaseName_width {
      width: 150px; 
      position: relative; 
      padding-right: 30px;
    }
    
    .password_width {
      width: 150px;
      position: relative; 
      padding-right: 30px; 
    }
  </style>
  </head>
  <body>
  <div>
  <h2>${
    data.nameVariants.length > 0 ? data.nameVariants[0] + " Report" : "Report"
  }</h2>
  <table>
    <tr>
      <th class="title-col">Title</th>
      <th>Details</th>
    </tr>
    <tr>
      <td class="title-col">Name Variant(s)</td>
      <td>${
        data.nameVariants.length > 0
          ? `<ul class="${
              data.nameVariants.length === 1
                ? "single-item-list"
                : "multi-item-list"
            } un-list">` +
            data.nameVariants.map((name) => `<li>${name}</li>`).join("") +
            "</ul>"
          : "Not Available"
      }</td>
    </tr>
    <tr>
      <td class="title-col">Image(s)</td>
      <td>${
        data.images.length > 0
          ? `<ul class="${
              data.images.length === 1 ? "single-item-list" : "multi-item-list"
            }">` +
            data.images.map((image) => `<li>${image}</li>`).join("") +
            "</ul>"
          : "Not Available"
      }</td>
    </tr>
    <tr>
      <td class="title-col">Age</td>
      <td>${data.age ? data.age : "Not Available"}</td>
    </tr>
    <tr>
  <td class="title-col">Religion</td>
    <td>${data.religion ? data.religion : "Not Available"}</td>
  </tr>
    <tr>
      <td class="title-col">Company Name</td>
      <td>${data.companyName ? data.companyName : "Not Available"}</td>
    </tr>
    <tr>
    <td class="title-col">Profession</td>
    <td>${data.profession ? data.profession : "Not Available"}</td>
  </tr>
  <tr>
    <td class="title-col">School</td>
    <td>${data.school ? data.school : "Not Available"}</td>
  </tr>
    <tr>
      <td class="title-col">Username(s)</td>
      <td>${
        data.usernames.length > 0
          ? `<ul class="${
              data.usernames.length === 1
                ? "single-item-list"
                : "multi-item-list"
            }">` +
            data.usernames.map((username) => `<li>${username}</li>`).join("") +
            "</ul>"
          : "Not Available"
      }</td>
    </tr>
    <tr>
      <td class="title-col">Email Address(s)</td>
      <td>${
        data.emails.length > 0
          ? `<ul class="${
              data.emails.length === 1 ? "single-item-list" : "multi-item-list"
            }">` +
            data.emails.map((email) => `<li>${email}</li>`).join("") +
            "</ul>"
          : "Not Available"
      }</td>
    </tr>
    <tr>
      <td class="title-col">Description(s)</td>
      <td>${
        data.descriptions.length > 0
          ? `<ul class="${
              data.descriptions.length === 1
                ? "single-item-list"
                : "multi-item-list"
            }">` +
            data.descriptions.map((desc) => `<li>${desc}</li>`).join("") +
            "</ul>"
          : "Not Available"
      }</td>
    </tr>
    <tr>
      <td class="title-col">Address(s)</td>
      <td>${
        data.addresses.length > 0
          ? `<ul class="${
              data.addresses.length === 1
                ? "single-item-list"
                : "multi-item-list"
            }">` +
            data.addresses.map((address) => `<li>${address}</li>`).join("") +
            "</ul>"
          : "Not Available"
      }</td>
    </tr>
    <tr>
      <td class="title-col">Qualification(s)</td>
      <td>${
        data.qualifications.length > 0
          ? `<ul class="${
              data.qualifications.length === 1
                ? "single-item-list"
                : "multi-item-list"
            }">` +
            data.qualifications
              .map((qualification) => `<li>${qualification}</li>`)
              .join("") +
            "</ul>"
          : "Not Available"
      }</td>
    </tr>
    <tr>
      <td class="title-col">Number(s)</td>
      <td>${
        data.numbers.length > 0
          ? `<ul class="${
              data.numbers.length === 1 ? "single-item-list" : "multi-item-list"
            }">` +
            data.numbers.map((number) => `<li>${number}</li>`).join("") +
            "</ul>"
          : "Not Available"
      }</td>
    </tr>
    <tr>
      <td class="title-col">Social Profile(s)</td>
      <td>${
        data.socialHandles.length > 0
          ? `<ul class="${
              data.socialHandles.length === 1
                ? "single-item-list"
                : "multi-item-list"
            }">` +
            data.socialHandles
              .map((internetAddress) => {
                const isUrl =
                  internetAddress.id.startsWith("http://") ||
                  internetAddress.id.startsWith("https://");
                const url = isUrl
                  ? internetAddress.id
                  : `https://${internetAddress.service}.com/${internetAddress.id}`;

                return `<li><a href="${url}" target="_blank">${internetAddress.service}: ${internetAddress.id}</a></li>`;
              })
              .join("") +
            "</ul>"
          : "Not Available"
      }</td>
    </tr>
    <tr>
    <td class="title-col">CNIC(s)</td>
    <td>${
      data.cnics.length > 0
        ? `<ul class="${
            data.cnics.length === 1 ? "single-item-list" : "multi-item-list"
          }">` +
          data.cnics.map((cnic) => `<li>${cnic}</li>`).join("") +
          "</ul>"
        : "Not Available"
    }</td>
  </tr>
  </table>
  <div>
  <p class="leaked-credentials-data">Leaked Credentials</p>
  <table>
    <tr>
      <th>S.No</th>
      <th>Email</th>
      <th>Database Name</th>
      <th>Password</th>
    </tr>
    ${
      data.leakedCredentials && data.leakedCredentials.length > 0
        ? data.leakedCredentials
            .map(
              (credential, index) => `
              <tr>
              <td >${index + 1}</td>
              <td class="overflow-ellipsis">
                ${
                  credential.email
                    ? `${credential.email} <i class="uil uil-copy copy-icon" onclick="copyTextToClipboard('${credential.email}')"></i>`
                    : "Not Available"
                }
              </td>
              <td class="overflow-ellipsis">
                ${
                  credential.databaseName
                    ? `${credential.databaseName} <i class="uil uil-copy copy-icon" onclick="copyTextToClipboard('${credential.databaseName}')"></i>`
                    : "Not Available"
                }
              </td>
              <td class="overflow-ellipsis">
                ${
                  credential.password
                    ? `${credential.password} <i class="uil uil-copy copy-icon" onclick="copyTextToClipboard('${credential.password}')"></i>`
                    : "Not Available"
                }
              </td>
            </tr>
        `
            )
            .join("")
        : '<tr><td colspan="4" class="no-data-found">No Data Found</td></tr>'
    }
  </table>
</div>
  </div>
  <script src="https://unicons.iconscout.com/release/v3.0.6/script/monochrome/bundle.js"></script>
  <script>
  function copyTextToClipboard(text) {
    navigator.clipboard.writeText(text).then(() => {
      console.log('Text copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  }
  </script>
  </body>
  </html>
  `;
      const blob = new Blob([htmlContent], { type: "text/html" });
      const url = window.URL.createObjectURL(blob);
      const downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = "Report.html";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to download HTML file.");
      return;
    } finally {
      setModelLoading(false);
    }
  };
  return (
    <div className="outer_container_dashboard ">
      <div className="dashboard-container">
        <div className="table-header">
          <h2>My Investigations</h2>
        </div>
        {loading ? (
          <div className="loader-background">
            <h2>Loading tasks...</h2>
          </div>
        ) : (
          <></>
        )}
        {!loading && (
          <>
            <table className="task-table">
              {items?.length === 0 ? (
                <div className="no-items-message">No tasks found.</div>
              ) : (
                <>
                  <thead>
                    <tr onClick={handleHeaderClick}>
                      <TableHeading columnName="Date" />
                      <TableHeading columnName="Leads" />
                      <TableHeading columnName="Type" />
                      <TableHeading columnName="Status" />
                      <TableHeading columnName="Action" />
                    </tr>
                  </thead>
                  <tbody>
                    {items?.map((item, index) => (
                      <tr key={index} className="task-table-data-row">
                        <td>
                          {item?.createdAt
                            ? convertTimestampToIST(item.createdAt)
                            : "N.A."}
                        </td>
                        <td>
                          {item.leads ? item.leads : "N.A."}
                          <UilInfoCircle
                            size="1.1em"
                            data-tooltip-id="my-tooltip-1"
                            onMouseOver={() => handleMouseOver(item._id)}
                            style={{ border: "none", outline: "none" }}
                          />{" "}
                        </td>
                        <td className="keyword-list">
                          {item.type ? item.type : "N.A."}
                        </td>
                        <td>
                          {" "}
                          <span
                            className="status"
                            style={{
                              backgroundColor:
                                item.status === true && "#32BA32",
                            }}
                          >
                            {item.status ? "Completed" : "In Progress"}
                          </span>{" "}
                        </td>
                        <td>
                          <button
                            className="create_task_view_button"
                            onClick={() => handleViewClick(item)}
                            disabled={!item.status}
                          >
                            View <UilEye size="1.1em" />{" "}
                          </button>
                          <button
                            onClick={(e) => handleBarsButtonClick(item._id, e)}
                            className="create_task_three_dots"
                          >
                            <UilEllipsisV />
                          </button>
                          {openDropdownId === item._id && (
                            <div
                              ref={dropdownRef}
                              className="hamburger-createTask"
                            >
                              <button
                                onClick={() =>
                                  handleDeleteButtonClick(item._id)
                                }
                                disabled={!item.status}
                              >
                                <UilTrashAlt /> Delete
                              </button>
                              <div></div>
                              <button
                                disabled={!item.status || modelLoading}
                                onClick={downloadHTML}
                              >
                                <UilImport /> Download
                              </button>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </>
              )}
            </table>

            {!loading && items?.length > 0 && (
              <Pagination page={page} setPage={setPage} fetchData={fetchData} />
            )}
          </>
        )}
        <ReactTooltip
          id={`my-tooltip-1`}
          place="right"
          effect="solid"
          backgroundColor="white"
          border="#1b2e4b"
        >
          {tootTipContent && (
            <div className="tooltip-container">
              <strong>Input</strong>
              <ul
                className="tooltip-list"
                style={{
                  marginTop: "8px",
                }}
              >
                {Object.keys(tootTipContent?.input).map((type) => {
                  const hasContent = tootTipContent?.input[type]?.length > 0;

                  return (
                    hasContent && (
                      <li key={type} className="tooltip_inner_loop">
                        {hasContent && (
                          <strong>
                            {type.charAt(0).toUpperCase() + type.slice(1)}:
                          </strong>
                        )}
                        {hasContent && (
                          <ul>
                            {tootTipContent?.input[type]?.map(
                              (value, index) => (
                                <li key={index} className="tooltip_inner_loop">
                                  {typeof value === "object"
                                    ? Object.keys(value).join(", ")
                                    : value}
                                </li>
                              )
                            )}
                          </ul>
                        )}
                      </li>
                    )
                  );
                })}
              </ul>
            </div>
          )}
        </ReactTooltip>
      </div>

      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title className="text-black">Delete Investigation</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-black">
          Are you sure you want to delete this investigation?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseDeleteModal}
            disabled={deleteLoader}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={handleConfirmDelete}
            disabled={deleteLoader}
          >
            {deleteLoader ? "Deleting" : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showResponseModal}
        onHide={handleCloseResponseModal}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        className="modal-xl modal-dialog-centered"
      >
        <Modal.Header>
          <Modal.Title className="text-black">V Agent</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="text-white text-wrap overflow-scroll bg-[#202020]"
          style={{
            backgroundColor: "#202020",
          }}
        >
          {modelLoading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  letterSpacing: "1px",
                  color: "white",
                }}
              >
                Loading...
              </span>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : !modelLoading &&
            (!textData.responseData || !textData.mainKeys) ? (
            <span>No Results Found</span>
          ) : (
            <CreateTree
              mainKeys={textData.mainKeys}
              responseData={textData.responseData}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseResponseModal}
            disabled={modelLoading}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default TableDashboard;
